import { Controller } from "stimulus"
import Chart from "chart.js"
const axios = require('axios')

export default class extends Controller {

  static targets = ['container']

  connect() {
    let url = this.data.get('dataUrl');
    console.log(url);
    axios.get(url).then((response) => {
      console.log(response)
      this.generateChart(response.data)
    })
  }

  generateChart(data) {
    var that = this;
    Chart.plugins.register({
      beforeDraw: function(chartInstance) {
        var ctx = chartInstance.chart.ctx;
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, chartInstance.chart.width, chartInstance.chart.height);
      }
    });
    new Chart(this.containerTarget, {
      type: this.data.get('type'),
      data: data,
      options: {
        tooltips: {
          enabled: true,
          mode: 'label',
          callbacks: {
            label: function(tooltipItem, data) {
              console.log('tooltipItem: '+tooltipItem);
              console.log('data: '+data)
              return that.formatDuration(tooltipItem.yLabel, data.mins_per_day);
            }
          }
        }
      }
    })
  }

  formatDuration(mins, mins_per_day) {
    var days = Math.floor(mins / mins_per_day)
    var remainder = mins - (days * mins_per_day)

    var duration = moment.duration(mins, 'minutes')
    var hours = duration.hours() + ' hours'
    var mins = duration.minutes() + ' mins';

    if (days > 0) {
      duration.add(days, 'days')
      return duration.days() + ' days, ' + hours + ', ' + mins
    }
    else if (hours > 0) {
      return hours + ', ' + mins
    }
    else {
      return mins
    }

  }

}
