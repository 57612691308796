import { Controller } from "stimulus"

const axios = require('axios')



export default class extends Controller {

  
  connect(){
    axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.defaults.headers['Accept'] = 'application/json';
  }

  

  toggle(e){
    e.preventDefault   

    axios.get('/users/toggle_dark_mode').then(
      response => {
        let body = document.body
        if(response.data.body_class == 'true')
        {
          body.classList.remove('light')
          body.classList.add('dark')
        }
        else
        {
          body.classList.remove('dark')
          body.classList.add('light')
        }
      }
    )  

   
  } 

}
