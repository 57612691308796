
import { Controller } from "stimulus"
const axios = require('axios')

//(window.innerHeight + window.pageYOffset) + 100 >= document.body.offsetHeight)

export default class extends Controller {



  connect() {
    this.currentPage = parseInt(this.data.get("current-page")) || 0
    this.getting = false
    this.gotAll = this.data.get("got-all") === "true"
    this.getNextPage()
  }

  toggleSpinner(){
    let elem = document.getElementById("infinite-scroll-spinner")
    elem.classList.toggle("d-none")
  }

  getNextPage(){
    if(this.gotAll || this.getting )
    {
      return
    }
    let that = this
    let url = this.data.get("url")

    if(this.currentPage === 0 || (window.innerHeight + window.pageYOffset + 200) >= document.body.offsetHeight && this.gotAll == false)
    {
      this.getting = true
      this.toggleSpinner()
      axios.get(url,{
        params: {
          page: this.currentPage + 1
        }
      }).then(response => {
        that.element.innerHTML = (that.element.innerHTML + response.data.html)
        that.currentPage = that.currentPage + 1
        that.data.set("current-page", that.currentPage)
        that.getting = false
        that.toggleSpinner()
        that.gotAll = response.data.last
        let getAllChildren = document.getElementById("got-all-children")
        if(that.gotAll && getAllChildren === null)
        {
          that.alertLast()
          that.data.set("got-all", "true")
        }

        that.checkWidth()

      })
    }

  }

  alertLast(){
    let div = document.createElement("div")
    div.classList.add("alert")
    div.classList.add("alert-success")
    div.classList.add("text-center")
    div.id="got-all-children"
    div.innerHTML= "All " + this.data.get("childrenName") + " are now loaded"
    this.element.parentNode.insertBefore(div, this.element.nextSibling);
    let elem = document.getElementById("infinite-scroll-spinner")
    if (!elem.classList.contains("d-none"))
    {
      elem.classList.add("d-none")
    }
  }

  checkWidth(){
    let cm = this.application.getControllerForElementAndIdentifier(this.element, 'commentsmain')
    if(cm){
      cm.widthCheck()
    }
  }



}
