import { Controller } from "stimulus"
//import { Rails } from "@rails/ujs"
const axios = require('axios')


export default class extends Controller {

  static targets = ["ticketForm", "activitySubmit"]

  connect() {
    axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
  }

  initialize() {
    this.formDirty = false
  }

  markDirty() {
    if (this.formDirty == false) this.formDirty = true
  }

  submitForms(e) {
    console.log('I am here')
    if (this.formDirty) {
      e.preventDefault()
      let fData = new FormData(this.ticketFormTarget)
      var that = this
      axios.put(this.ticketFormTarget.getAttribute('action'), fData).then(res => {
        if (res.status == 200) {
          //document.getElementById('new_activity').submit()
          Rails.fire(document.getElementById('new_activity'), 'submit')
        }
        else {
          console.log(res)
        }
      }).catch(res => {
        console.log(res)
        let err = document.createTextNode(res.response.data.msg)
        let alert = document.createElement('div')
        alert.classList.add('alert', 'alert-danger')
        alert.appendChild(err)
        document.getElementById('main').insertBefore(alert, that.ticketFormTarget)
        window.scroll({
          top: alert.offsetTop - 15,
          left: 0,
          behavior: 'smooth'
        });
      })
    }
  }

}