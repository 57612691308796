import { Controller } from 'stimulus';
import Swal from 'sweetalert2'

export default class extends Controller {
    static targets = ['link'];

    linkClicked(event) {
        let msg = this.linkTarget.getAttribute('data-message')
        this.linkTarget.blur()
        if(msg){
            event.preventDefault()
            event.stopImmediatePropagation()
            let self = this
            Swal({
                title: msg,
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'Cancel',
            }).then(result => self.confirmed(result))
        }
    }

    confirmed(result){
        if(result.value){
            this.linkTarget.removeAttribute('data-message')
            this.linkTarget.click()
        }
    }

}