import { Controller } from 'stimulus';
import Swal from 'sweetalert2'

const axios = require('axios')
let polling = null
let count = 0

export default class extends Controller {

    connect() {
        axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        axios.defaults.headers.post['Content-Type'] = 'application/json';
        axios.defaults.headers['Accept'] = 'application/json';
        this.startPolling()
    }

    startPolling(){
        let self = this;
        polling = setInterval(() => {
            axios.get('/users/validate_user_session').then(response => {
                if(response.data.show_alert && !response.data.time_expired && !Swal.isVisible() && count == 0){
                    count = 1;
                    Swal({
                        title: 'You will be automatically logged out soon',
                        text: 'Click Yes if you would like to extend your session',
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'Cancel',
                    }).then(result => self.confirmed(result))
                }
            })
            .catch(error => {
                if(error.response.status == 401){
                    window.location.replace("/users/sign_in")
                }
            })

        }, 30000)
    }

    stopPolling(){
        clearInterval(polling);
    }

    disconnect() {
        this.stopPolling();
    }

    confirmed(result){
        let self = this;
        if(result.value){
            axios.get('/users/renew_user_session').then(response => {
                count = 0;
            })
        }
    }

}