import { Controller } from "stimulus"

const axios = require('axios')



export default class extends Controller {

    static targets = ['downloadLink']

    getResource(ev){
        ev.preventDefault()
        let element = ev.target
        let url = element.getAttribute("data-file-url")
        let filename = element.getAttribute("data-filename")
        let mimetype = element.getAttribute("data-mime-type")
        let self = this

        if(url == ''){
            this.showAlert('Missing Url for this attachment')
            return
        }

        let config = {
            responseType: 'blob'
        }

        axios.get(url, config).then(
            function(response) {
                self.downloadInBrowser(response, filename, mimetype);
            },
            function(error) {
                self.showAlert(error.message)
            }
        );
    }

    downloadInBrowser(response, filename, mimetype){
        const url = window.URL.createObjectURL(new Blob([response.data], {type: mimetype}))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
    }

    showAlert(errorMsg){
        let wrapper = document.createElement('div')
        wrapper.innerHTML = '<div class="alert alert-danger"><button type="button" class="close" data-dismiss="alert" aria-label="Close">&times;</button>' +
            'There was a problem downloading this attachment: ' + errorMsg +
            '</div>';
        let alertHolder = document.querySelector('div #limit-width')
        alertHolder.insertBefore(wrapper.firstChild, alertHolder.firstChild)
    }


}