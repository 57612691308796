import {Controller} from "stimulus"

const axios = require('axios')

/*
This controller is designed to be round each email but also round each tab pane to scope things successfully
So some methods are for each email and some for each tab - using a custom event to communicate between the two
*/

export default class extends Controller {
    static targets = ["body","bigButton", "id", "idInput", "feedbackMessage"]

    connect(){
      this.toggleEvent = new Event("held-emails.toggled")

    }
    //for each email just the toggle
    toggle(){
      $(this.bodyTarget).collapse("toggle")
      document.dispatchEvent(this.toggleEvent)
    }


    //for each tab pane
    closeAll(e){
        let that=this
        let bodies = document.querySelectorAll(["#", this.data.get('tabpane'), " .held_email_body"].join(""))

        Array.from(bodies).forEach( function(div){

          if(div.classList.contains("show"))
          {
            div.classList.remove("show")
          }

        })

        this.buttonToOpen(this.bigButtonTarget)

    }

    openAll(e){
      let bodies = document.querySelectorAll(["#", this.data.get('tabpane'), " .held_email_body"].join(""))
      let that=this
      Array.from(bodies).forEach( function(div){

        if(div.classList.contains("show"))
        {

        }
        else
        {
          div.classList.add("show")
        }

      })

      this.buttonToClose(this.bigButtonTarget)

    }

    buttonToOpen(button){
      button.dataset.action = "click->held-emails#openAll"
      button.innerHTML="Expand All"
    }

    buttonToClose(button){
      button.dataset.action = "click->held-emails#closeAll"
      button.innerHTML="Close All"
    }

    hasToggled(){
      if(this.countOpen() > 1)
      {
        this.buttonToClose(this.bigButtonTarget)
      }
      else
      {
        this.buttonToOpen(this.bigButtonTarget)
      }
    }

    countOpen(){
      //NB this count occurs before the new one is open so is always one less then it really is
      // once 3 are open I think the button should become closeAll rather than open All for user convenience
      let count= document.querySelectorAll(["#", this.data.get('tabpane'), " .held_email_body.show"].join("")).length
      console.log(count)
      return(count)
    }


    toActivity(e){
      //return console.log(this.data.get("url"))
      let that = this
      axios.post( this.data.get("url"),{
          object_id: this.idInputTarget.value,
          object: e.target.dataset.className
      }).then(function(response){
        if(response.data.error)
        {
          that.feedbackMessageTarget.classList.remove("alert-info")
          that.feedbackMessageTarget.classList.add("alert-danger")
          that.feedbackMessageTarget.innerHTML=response.data.msg
          that.feedbackMessageTarget.classList.remove("d-none")
        }
        else
        {
          let held = document.getElementById("held-email-" + response.data.held_email_id)
          if(held)
          {
            held.remove()
          }
          bootbox.alert(response.data.msg)
        }
      })
    }


}
