import { Controller } from "stimulus"

const axios = require('axios')
const Fingerprint2 = require("fingerprintjs2")


export default class extends Controller {
  static targets = [ "useremail","formemail","firstform","secondform","errordiv", "otp", "psubmit", "otpContainer", 'fingerprint', 'fingerprintdata' ]

  connect() {
    axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    axios.defaults.headers['Accept'] = 'application/json';
  }

  finger_print(){
    var that = this
    var excludes = {
      screenResolution: true, 
      userAgent: true, 
      enumerateDevices: true, 
      availableScreenResolution: true, 
      doNotTrack: true, 
      fonts: true,
      timezoneOffset: true,
      pixelRatio: true
    }
    if (window.requestIdleCallback) {
      requestIdleCallback(function () {
        Fingerprint2.get({excludes: excludes },function (components) {
          console.log(components)
          var values = components.map(function (component) { return component.value })
          console.log(Fingerprint2.x64hash128(values.join(''), 31))
          that.fingerprintTarget.value = Fingerprint2.x64hash128(values.join(''), 31)
          that.fingerprintdataTarget.value = JSON.stringify(components)
        })
      })
    } else {
      setTimeout(function () {
        Fingerprint2.get({excludes: excludes}, function (components) {
          var values = components.map(function (component) { return component.value })
          console.log(components)
          console.log(Fingerprint2.x64hash128(values.join(''), 31))
          that.fingerprintTarget.value = Fingerprint2.x64hash128(values.join(''), 31)
          that.fingerprintdataTarget.value = JSON.stringify(components)
        })
      }, 500)
    }
  }

  check_email(){
    var email = this.useremailTarget.value
    var that = this
    this.finger_print()
    axios(("/users/two_factor_enabled?email=" + email), {
         method: 'GET'
       }).then(function(json){
               if(json.data.user)
               {
                 that.errordivTarget.classList.remove("d-block")
                 that.errordivTarget.classList.remove("d-none")
                 that.formemailTarget.value = email
                 that.formemailTarget.type = "hidden"
                 that.firstformTarget.classList.remove("d-block")
                 that.firstformTarget.classList.add("d-none")
                 that.secondformTarget.classList.remove("d-none")
                 that.secondformTarget.classList.add("d-block")
                 if(json.data.enabled === true)
                 {
                   that.otpTarget.classList.remove("d-none")
                   that.otpTarget.classList.add("d-block")
                   that.otpContainerTarget.classList.remove("d-none")
                   that.psubmitTarget.classList.remove("d-block")
                   that.psubmitTarget.classList.add("d-none")

                 }
                 else
                 {
                   that.otpTarget.classList.add("d-none")
                   that.otpTarget.classList.remove("d-block")
                   that.otpContainerTarget.classList.add("d-none")
                   that.otpContainerTarget.classList.remove("d-block")
                   that.psubmitTarget.classList.remove("d-none")
                 }
               }
               else
               {
                 if(json.data.error)
                 {
                   that.errordivTarget.innerHTML=json.data.error
                   that.errordivTarget.classList.remove("d-none")
                   that.errordivTarget.classList.add("d-block")
                 }
                 else
                 {
                   that.errordivTarget.innerHTML= "Sorry, something went wrong, please try again"
                   that.errordivTarget.classList.remove("d-none")
                   that.errordivTarget.classList.add("d-block")
                 }
               }

             })
  }

  forgotpwd(e){
    e.preventDefault()
    if( e.target.dataset['clicked'] )
    {
      return
    }
    else
    {
      e.target.dataset['clicked'] = true
    }
    let email = this.formemailTarget.value
    axios(

      {
        method: "POST",
        url:"/users/password",
        data: {
          email: email
        }
      }
    ).then(function(json){
      if(json.data.ok)
      {
        alert("Email is being sent to your address. Please use the link in that email to reset your password")
      }
      else
      {
        alert("Sorry unable to reset your password: " + json.data.error)
      }
    })
  }

}
