import { Controller } from "stimulus"
import Chart from "chart.js"
import * as ChartAnnotation from 'chartjs-plugin-annotation'
const axios = require('axios')

let chart_instance = null
let start_date, end_date

export default class extends Controller {

  static targets = ['container', 'department', 'form']

  initialize() {
    start_date = this.formTarget.time_spent_filter_start_date.value
    end_date   = this.formTarget.time_spent_filter_end_date.value

    if (start_date != '' && end_date != '') {
      this.generateChart();
    }
  }

  generateChart() {
    var that = this;
    axios.get('/time_spent_on_activities_report', {
      params: {
        start_date: start_date,
        end_date:   end_date,
        role:       that.departmentTarget.value
      }
    }).then(resp => {
      that.createChart(resp.data)
    });
  }

  createChart(data) {
    var that = this
    Chart.plugins.register({
      beforeDraw: function(chartInstance) {
        var ctx = chartInstance.chart.ctx;
        ctx.fillStyle = "white";
        ctx.fillRect(0, 0, chartInstance.chart.width, chartInstance.chart.height);
      }
    });
    chart_instance = new Chart(that.containerTarget, {
      type: 'bar',
      data: data,
      responsive: true,
      plugins: [ChartAnnotation],
      options: {
        tooltips: {
          enabled: true,
        },
        title: {
          text:  that.title,
          display: true,
          fontSize: 16
        },
        scales: {
          xAxes: [{
            stacked: true,
            scaleLabel: {
              display: true,
              labelString: 'People'
            }
          }],
          yAxes: [{
            stacked: true,
            scaleLabel: {
              display: true,
              labelString: 'Hours Spent'
            },
            ticks: {
              beginAtZero: true,
            },
            type: 'linear',
          }]
        },
        annotation: {
          annotations: [
            {
              drawTime: "beforeDatasetsDraw",
              type: "line",
              mode: "horizontal",
              scaleID: "y-axis-0",
              value: data.target_hours,
              borderColor: "black",
              borderWidth: 3
            }
          ]
        }
      }
    })
  }

  //updateChart(data) {
  //  chart_instance.data = data
  //  chart_instance.config.options.annotation.annotations[0].value = data.target_hours
  //  chart_instance.config.options.title.text = this.title
  //  chart_instance.update();
  //}

  get title() {
    return this.departmentTarget.value +' Time Spent Report from '+ this.formTarget.time_spent_filter_start_date.value+' to '+ this.formTarget.time_spent_filter_end_date.value
  }

}
