import {Controller} from "stimulus"

const axios = require('axios')

export default class extends Controller {
    static targets = ["mainAddressId", "selectedOrgId"]

    connect() {
    }

    resetSelect(select) {
        var length = select.options.length;
        for (var i = length - 1; i >= 0; i--) {
            select.options[i] = null;
        }
    }

    updateAddresses() {
        var org_id = this.selectedOrgIdTarget.value
        var addresses = this.mainAddressIdTarget
        this.resetSelect(addresses)

        console.log(org_id)
        axios.get('/contacts/load_organisation_addresses',
            {
                params: {
                    org_id: org_id
                }
            })
            .then(function (response) {
                console.log( response.data)
                for (var index = 0; index < response.data.length; index++) {
                    var item = response.data[index]
                    let option = document.createElement('option')
                    option.value = item['id']
                    option.text = item['name']
                    console.log(option)
                    addresses.appendChild(option)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }


}