import { Controller } from "stimulus"

//const axios = require('axios')

export default class extends Controller {


  toggleRows(e){
    switch(e.target.id){
      case "backfail":
        e.target.checked ? this.showRows("failure") :this.hideRows("failure")
      break;
      case "backwarn":
        e.target.checked ? this.showRows("warning") : this.hideRows("warning")
      break;
      case "backsucc":
        e.target.checked ? this.showRows("success"): this.hideRows("success")
      break;
      case "backmiss":
        e.target.checked ? this.showRows("missed") : this.hideRows("missed")
      break;
      case "backnotapplic":
        e.target.checked ? this.showRows("na") : this.hideRows("na")
      break;
    }
  }


  hideRows(klass){
    Array.from(document.querySelectorAll(".table-dashboard tr." + klass)).forEach(function(row){
      row.classList.add("d-none")
    })
  }

  showRows(klass){
    Array.from(document.querySelectorAll(".table-dashboard tr." + klass)).forEach(function(row){
      row.classList.remove("d-none")
    })
  }

}
