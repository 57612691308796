import { Controller } from "stimulus"

const axios = require('axios')

export default class extends Controller {

    static targets = ['sortTable'];

    connect(){
        axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        axios.defaults.headers.post['Content-Type'] = 'application/json';
        axios.defaults.headers['Accept'] = 'application/json';


        this.getTableData()
        this.startTimer()
    }

    disconnect() {
        this.stopTimer()
    }

    getTableData(){
        let url = this.sortTableTarget.getAttribute('data-url')
        var that = this
        axios(url, {
            method: 'GET',
            params: {
                sorts: that.sortData
            }
        }).then(function(json){
            let bodyId = that.sortTableTarget.getAttribute('data-bodyid')
            let countEl = that.sortTableTarget.getAttribute('data-countel')
            var myTickets = document.getElementById(bodyId)
            var count = document.getElementById(countEl)
            myTickets.innerHTML = json.data.table_body
            count.innerHTML = json.data.count

            // Set updated at time
            var time = moment().format('DD-MMM-YYYY HH:mm')
            document.querySelector('.last-updated .time').innerText = time;
        })

    }

    setClasses() {
        this.element.querySelectorAll('.sort_link').forEach(a => {
            a.classList.remove('asc')
        a.classList.remove('desc')
    })

        console.log(this.sortData)

        let tableId = this.sortTableTarget.getAttribute('data-tableid')

        console.log(tableId)

        if (this.sortData.sort_table) {
            var sortClass = this.sortData.sort_table
            document.querySelector(`${tableId} [data-column="${sortClass.column}"]`).classList.add(sortClass.order)
        }


    }

    changeSort(e) {
        e.preventDefault()
        this.updateSortData('sort_table', e.target.dataset.column)
    }

    updateSortData(type, column) {
        var data = this.sortData, order

        if (data[type] && data[type]['column'] == column) {
            order = data[type]['order'] == 'asc' ? 'desc' : 'asc'
        }
        else {
            order = 'asc'
        }
        data[type] = {
            column: column,
            order: order
        }

        this.sortData = JSON.stringify(data)
    }

    get sortData() {
        return JSON.parse(this.data.get('sorts'))
    }

    set sortData(sortHash) {
        this.data.set('sorts', sortHash)
        this.setClasses()
        this.getTableData()
    }

    startTimer(){
        var that = this
        that.liveTimer = setInterval(function(){that.getTableData()}, 300000)
    }

    stopTimer(){
        if(this.liveTimer) {
            clearInterval(this.liveTimer)
        }
    }
}